<template>
    <div class="map" :id="generateId"></div>
</template>

<script>

export default {
    props: {
		id: Number,
		lat: String,
		lng: String,
    },
    data() {
        return {
            map: null,
        }
    },
    computed:{
        generateId(){ 
            return "map" + this.id + Math.random().toString(16).slice(2); 
        }
    },
    mounted() {
        // eslint-disable-next-line no-undef
        this.map = L.map(this.generateId, {
            attributionControl: false,
            doubleClickZoom: true
        }).setView([this.lat, this.lng], 10);

        // eslint-disable-next-line no-undef
        L.marker([this.lat, this.lng]).addTo(this.map);

        // eslint-disable-next-line no-undef
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap'
        }).addTo(this.map);
    },
}
</script>

<style>
	.map {
		height: 200px;
        width: 200px;
	}
</style>

